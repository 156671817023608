const skippedPublishedTags = [
  'skip-publish',
  'skip-publish-en',
  'skip-publish-es',
  'skip-publish-de',
  'skip-publish-it',
  'skip-publish-fr'
]

export class PageFilterService {
  constructor(private tagsService) {
  }

  async getSkipPublishTags() {

    const tags: Tag[] = await this.tagsService.getTags()

    return tags.filter((tag) => {
      return skippedPublishedTags.includes(tag.slug)
    }).map((tag) => {
      return tag.id
    })
  }
}
