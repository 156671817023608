
import { AINOBLOCKS_ACCORDION_FAQ_BLOCK } from "~/constants/blocks"
import { getBlockAttribute } from "~/utils/block"

export default {
  name: "FaqsOriginal",

  props: {
    block: {
      type: Object, // Record<string, any>
      required: true
    }
  },

  data() {
    return {
      AINOBLOCKS_ACCORDION_FAQ_BLOCK,
      activeFaq: null
    }
  },

  head() {
    return {
      script: [{ type: "application/ld+json", json: this.getSchema() }]
    }
  },

  methods: {
    setActiveFaq(faqIndexClicked) {
      this.activeFaq = faqIndexClicked === this.activeFaq ? null : faqIndexClicked
    },
    getSchema() {
      const entries = this.block.innerBlocks
        .filter(innerBlock => innerBlock.type === this.AINOBLOCKS_ACCORDION_FAQ_BLOCK)
        .map(entry => {
          let answer = ""

          entry.innerBlocks.forEach(block => {
            answer += block.innerHtml
          })

          return {
            "@type": "Question",
            name: getBlockAttribute(entry.attributes, "question"),
            acceptedAnswer: {
              "@type": "Answer",
              text: answer
            }
          }
        })

      if (!entries) {
        return null
      }

      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: entries
      }
    }
  }
}
