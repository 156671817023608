
import { Vue } from "nuxt-property-decorator"
export default Vue.extend({
  name: "CategoryCard",
  props: {
    title: {
      type: String,
      required: true
    },
    linkPath: {
      type: String,
      required: true
    },
    imagePeeksOut: {
      type: Boolean,
      default: false
    },
    animateFrom: {
      type: String as () => "right" | "bottom",
      default: "right"
    }
  },
  computed: {
    getLocalePath() {
      // Updates the header card links with the locale URL
      if (this.$i18n.locale === "en") {
        return this.$config.v4Host
      }
      return this.$config.v4Host.replace("com", this.$i18n.locale)
    }
  }
})
