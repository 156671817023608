
import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class AnyVanLogoComponent extends Vue {
  @Prop({ type: String, default: 'white' }) color: string
  @Prop({ type: Number, default: 140 }) width: number
  @Prop({ type: Number, default: 24 }) height: number
  @Prop({ type: Boolean, default: true }) full: boolean
  @Prop({ type: Boolean, default: false }) withoutLink: boolean
}
