
import { Vue } from "nuxt-property-decorator"
import { SnowplowService } from "~/services/public/Snowplow";

export default Vue.extend({
  name: "HomePageHeader",
  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },
  head() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "MovingCompany",
            name: "AnyVan",
            alternateName: ["Any Van", "AnyVan.com", "Anyvan"],
            telephone: "+442030056000",
            url: "https://www.anyvan.com",
            foundingDate: "March 2009",
            founders: [
              {
                "@type": "Person",
                name: "Angus Elphinstone"
              }
            ]
          }
        }
      ]
    }
  },
  data() {
    return {
      emailFormShow: false
    }
  },
  mounted() {
    const makeLinkTogglable = (link: Node) => {
      link.addEventListener("click", () => {
        this.toggleModal()
      })
    }
    const quoteLink = document.querySelectorAll(".quote-link")
    if (quoteLink && quoteLink.length) {
      quoteLink.forEach(makeLinkTogglable)
    }

    const buttons = document.querySelectorAll(".homepage-ctas .av-buttons")
    if (buttons && buttons.length) {
      buttons.forEach(button => {
        button.addEventListener("click", () => {
          this.sendHomePageCtaTrackingEvent()
        })
      })
    }
  },
  methods: {
    toggleModal() {
      this.emailFormShow = !this.emailFormShow
    },
    sendHomePageCtaTrackingEvent() {
      const snowPlow = new SnowplowService()
      if (snowPlow) {
        snowPlow.trackHomePageCtaClick()
      }
    }
  }
})
