import { render, staticRenderFns } from "./TheHeaderES.vue?vue&type=template&id=0479bc01&lang=pug"
import script from "./TheHeaderES.vue?vue&type=script&lang=ts"
export * from "./TheHeaderES.vue?vue&type=script&lang=ts"
import style0 from "./TheHeaderES.vue?vue&type=style&index=0&id=0479bc01&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnyVanLogo: require('/app/components/AnyVanLogo.vue').default,PhoneNumber: require('/app/components/generic/PhoneNumber.vue').default,LoginButton: require('/app/components/LoginButton.vue').default,Icon: require('/app/components/vantone/Icon.vue').default,HeaderImageWhatWeDo1: require('/app/components/header/ImageWhatWeDo1.vue').default,HeaderImageWhatWeDo2: require('/app/components/header/ImageWhatWeDo2.vue').default,HeaderImageWhatWeDo3: require('/app/components/header/ImageWhatWeDo3.vue').default,HeaderImageWhatWeDo4: require('/app/components/header/ImageWhatWeDo4.vue').default,HeaderImageWhatWeDo5: require('/app/components/header/ImageWhatWeDo5.vue').default,HeaderImageWhatWeDo6: require('/app/components/header/ImageWhatWeDo6.vue').default,HeaderImageWhatWeDo7: require('/app/components/header/ImageWhatWeDo7.vue').default,HeaderImageWhatWeDo8: require('/app/components/header/ImageWhatWeDo8.vue').default,HeaderListItem: require('/app/components/header/ListItem.vue').default,HeaderCheckListItem: require('/app/components/header/CheckListItem.vue').default,LocalisedTrustpilotES: require('/app/components/localised/TrustpilotES.vue').default,HeaderImageBasicCounter: require('/app/components/header/ImageBasicCounter.vue').default,HeaderImageStatsES: require('/app/components/header/ImageStatsES.vue').default,HeaderImageStatsMobileES: require('/app/components/header/ImageStatsMobileES.vue').default,HeaderImageAVB: require('/app/components/header/ImageAVB.vue').default,HeaderImageTP: require('/app/components/header/ImageTP.vue').default})
