
import { Vue, Component, Prop, Emit } from "nuxt-property-decorator"

@Component
export default class LoginButton extends Vue {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: String, default: "" }) type: string
  @Prop({ type: String, default: "" }) username: string
  showDropdown: Boolean = false

  @Emit("input")
  toggleVisibility() {
    // Redirect to the locale login
    if (this.$i18n.locale !== "en") {
      window.location.href =
        this.$nuxt.$config.v4Host.replace(".com", "." + this.$i18n.locale) +
        "/login-with-credentials"
    }

    this.$emit("click")
    if (!this.value) {
      this.$emit("hide-panel-menu")
    }
    return !this.value
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown
  }

  redirect(location) {
    window.location = this.$config.v4Host + location
  }
}
