import * as Constants from "~/constants/blocks"
import { SnowplowService } from "~/services/public/Snowplow"
export class ScrollTrackingService {
  observer
  trackingHistory = {}
  snowPlowService
  constructor() {
    setTimeout(() => {
      this.observer = new IntersectionObserver(this.trackWithSnowPlow.bind(this), {
        threshold: [0.25, 0.5, 0.75, 1]
      })
      this.mapSections()
    }, 2000) // Timeout to avoid triggering the callback for all sections on page load.
    this.snowPlowService = new SnowplowService()
  }

  /**
   * This method selects all the section element from the document and creates the initial
   * trackingHistory property.
   * */
  mapSections() {
    const sectionsFromDocument = document.querySelectorAll('[id^="section-no-"]')
    sectionsFromDocument.forEach((section, key) => {
      this.observer.observe(section)
      this.trackingHistory[section.id] = {
        section_number: key + 1,
        section_name: this.findSectionClassName(section.classList.value?.split(" ")) ?? section.id,
        scroll_percentage: 0,
        reached_after: 0,
        tracking_completed: false
      }
    })
  }

  /**
   * To make the results more intelligible to those analysing
   * the data, we find the section classname by checking the constants
   * we define in our app constants and cross-reference that with the
   * classes on the dom element.
   *
   * @param sectionClassList an array containing strings for each class on the DOM element.
   * */
  findSectionClassName(sectionClassList) {
    const constants = Object.values(Constants)
    const result = sectionClassList.filter(className => constants.includes(className))
    if (result && result.length > 0) {
      return result[0]
    }
    return null
  }

  /**
   * The intersectionObserver will not 100% reliably track dom elements leaving the
   * viewport. This method checks previous sections the user has scrolled and ensures
   * that we finish off tracking scroll depth for those areas.
   *
   * @param sectionKey the section we are currently tracking.
   * */
  checkPreviousSection(sectionKey) {
    if (sectionKey === "section-no-0") return

    const match = sectionKey.match(/(\d+)$/)
    if (match) {
      const sectionNumber = parseInt(match[0], 10)
      const previousSection = sectionKey.replace(/\d+$/, sectionNumber - 1)
      if (!this.trackingHistory[previousSection].tracking_completed) {
        this.trackingHistory[previousSection].scroll_percentage = 100
        this.trackingHistory[previousSection].tracking_completed = true
        this.snowPlowService.trackPageScroll([this.trackingHistory[previousSection]])
      }
    }
    return false
  }

  /**
   * This is the callback function that is triggered by intersectionObserver
   * to track pagescroll events.
   *
   * @param entries all the dom elements we are observing
   * @param observer the observer used to track dom elements.
   * */
  trackWithSnowPlow(entries, observer) {
    entries.forEach(entry => {
      if (this.trackingHistory[`${entry.target.id}`].tracking_completed) {
        const targetElement = document.getElementById(entry.target.id)
        observer.unobserve(targetElement)
      }

      if (entry.isIntersecting) {
        // Set the scroll percentage:
        if (entry.boundingClientRect.y > 0) {
          this.setScrollPercentageForSection(entry.target.id, entry.intersectionRatio * 100)
          this.trackingHistory[`${entry.target.id}`].reached_after = Math.floor(entry.time / 1000)

          // Ensure previous section is fully tracked (required for some screen sizes to work correctly):
          if (this.trackingHistory[`${entry.target.id}`].scroll_percentage !== 0) {
            this.checkPreviousSection(entry.target.id)
          }
          this.snowPlowService.trackPageScroll([this.trackingHistory[`${entry.target.id}`]])
        }
      }
    })
  }

  /**
   * The intersectionObserverEntry intersectionRatio property will not be exactly
   * set at the thresholds we specified in the InsersectionObserver options.
   *
   * This method checks the percentage of the intersectionRatio and rounds to the
   * nearest threshold, so that we can track which section threshold we
   * need to track for the Snowplow request.
   *
   *
   * @param sectionId sectionID of the section.
   * @param percentage the intersectionRatio.
   * */
  setScrollPercentageForSection(sectionId, percentage) {
    if (percentage >= 25 && percentage < 50) {
      this.trackingHistory[sectionId].scroll_percentage = 25
    } else if (percentage >= 50 && percentage < 75) {
      this.trackingHistory[sectionId].scroll_percentage = 50
    } else if (percentage >= 75 && percentage < 100) {
      this.trackingHistory[sectionId].scroll_percentage = 75
    } else {
      this.trackingHistory[sectionId].scroll_percentage = 100
      this.trackingHistory[sectionId].tracking_completed = true
    }
  }
}
