
import { Vue } from "nuxt-property-decorator"
import { SnowplowService } from "~/services/public/Snowplow"

export default Vue.extend({
  name: "HeaderCardsVariant",
  data() {
    return {
      snowplowService: null as SnowplowService | null
    }
  },
  methods: {
    trackCardClick(title: string) {
      this.snowplowService?.trackCategoryCardClick(title)
    }
  },
  mounted() {
    this.snowplowService = new SnowplowService()
  }
})
