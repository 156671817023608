
/** Create a modal dialog */
export default {
  name: "Modal", // eslint-disable-line vue/multi-word-component-names

  props: {
    /** Modal width */
    size: {
      type: String,
      default: "m",
      validator: value => ["s", "m", "l", "xl", "xxl", "auto"].includes(value)
    },
    /** Close button */
    showClose: {
      type: Boolean,
      default: true
    },
    /** Padding 0 in modal content */
    noPadding: {
      type: Boolean,
      default: false
    },
    /** Top icon */
    icon: {
      type: Array,
      default: () => {
        return []
      }
    },
    /** Visible left-side content */
    visibleLeftSide: {
      type: Boolean,
      default: false
    },
    /** Modal fixed on right side of the window */
    fixedOnRight: {
      type: Boolean,
      default: false
    },
    /** Show confirmation layer */
    confirmSubmit: {
      type: Boolean,
      default: false
    },
    bgOpacity: {
      type: Number,
      default: 50,
      validator: value =>
        [0, 5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90, 95, 100].includes(value)
    },
    verticallyCentered: {
      type: Boolean,
      default: false
    },
    askEmailMobile: {
      type: Boolean,
      default: false
    },
    classic: {
      type: Boolean,
      default: true
    },
    transition: {
      type: Boolean,
      default: true
    },
    subModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showModal: false,
      timerid: null
    }
  },

  computed: {
    modalId() {
      if (typeof this.$el.id === "string" && this.$el.id !== "") {
        return this.$el.id
      }
      return "modal"
    },

    withIcon() {
      return this.icon.length >= 2
    },

    sizeClass() {
      return this.$style[this.size + "Size"]
    }
  },

  mounted() {
    const scrollWidth = Math.round(window.innerWidth - document.documentElement.clientWidth)
    document.documentElement.style.paddingRight = scrollWidth + "px"
    document.addEventListener("keydown", this.keyClose)

    if (this.classic) {
      document.documentElement.style.overflow = "hidden"
      document.documentElement.style.position = "relative"
      document.documentElement.style.height = "100%"
      document.body.style.overflow = "hidden"
      document.body.style.position = "relative"
    }

    // Adding the hash to the url was causing an issue with the router. Trying to navigate back or forward requires
    // and extra click. Removing for now.

    // this.timerid = setTimeout(() => {
    //   if (window.location.hash) {
    //     history.replaceState(null, null, `#${this.modalId}`)
    //   } else {
    //     history.pushState(null, null, `#${this.modalId}`)
    //   }
    // }, 200)

    this.showModal = true
  },

  beforeDestroy() {
    document.documentElement.style.paddingRight = "0"
    document.removeEventListener("keydown", this.keyClose)

    if (this.classic) {
      document.documentElement.style.overflow = "unset"
      document.documentElement.style.position = "static"
      document.documentElement.style.height = "auto"
      if (!this.subModal) {
        document.body.style.overflow = "unset"
        document.body.style.position = "static"
      }
    }

    if (window.location.hash && window.location.hash.substring(1) === this.modalId) {
      history.replaceState(null, null, " ")
    }

    if (this.timerid) {
      clearTimeout(this.timerid)
    }
  },

  methods: {
    keyClose(e) {
      if (e.keyCode === 27) {
        this.close()
      }
    },

    close(e) {
      if (typeof e === "undefined" || e.target.classList.contains(this.$style.modalMask)) {
        if (typeof e !== "undefined") e.stopPropagation()
        this.$emit("close")
      }
    }
  }
}
