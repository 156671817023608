
import { Component, Prop, mixins, Getter } from "nuxt-property-decorator"
import { LiveChatWidget } from "@livechat/widget-vue/v2"
import { ScrollTrackingService } from "~/services/public/ScrollTrackingService"
import Blocks from "~/mixins/blocks"
import { GET_INVENTORY } from "~/store/pages/getters"
import { SET_INVENTORY } from "~/store/pages/actions"
import { GET_CREDIT_CODE_VALUE } from "~/store/credit-code/getters"

@Component({
  components: {
    LiveChatWidget
  }
})
export default class PageLayoutRebrand extends mixins(Blocks) {
  @Prop({ required: true }) page: Page
  @Getter(GET_INVENTORY, { namespace: "pages" }) getInventory: boolean | null | undefined
  @Getter(GET_CREDIT_CODE_VALUE, { namespace: "credit-code" }) creditCodeValue: number | null
  blocks: Block[] = []
  created() {
    this.blocks = [...this.page?.blocks]
  }

  mounted() {
    const scrollTrackingService = new ScrollTrackingService()

    if (this.getInventory === undefined) {
      this.$store.dispatch("pages/" + SET_INVENTORY, false)
    }
  }
}
