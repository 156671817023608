import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=107d19a2&lang=pug"
import script from "./Modal.vue?vue&type=script&lang=js"
export * from "./Modal.vue?vue&type=script&lang=js"
import style0 from "./Modal.vue?vue&type=style&index=0&id=107d19a2&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/components/vantone/Icon.vue').default})
