// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.comparisonTableSection_Nwn3\\+ {\n  display: flex;\n  flex-direction: column-reverse;\n  row-gap: 64px;\n  font-size: 1rem;\n}\n.comparisonTableSection_Nwn3\\+.comparison-table-reverse {\n  flex-direction: column;\n}\n.comparisonTableSection_Nwn3\\+ > * {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n.comparisonTableSection_Nwn3\\+ .av-heading {\n  margin: 0;\n  margin-bottom: 32px;\n}\n@media (min-width: 992px) {\n.comparisonTableSection_Nwn3\\+ .av-heading {\n    margin-bottom: 64px;\n}\n.comparisonTableSection_Nwn3\\+ {\n    display: grid;\n    align-items: center;\n    grid-row-gap: 64px;\n    row-gap: 64px;\n    grid-template-columns: 5fr 1fr 6fr;\n    grid-template-areas: \"table gap content\";\n}\n.comparisonTableSection_Nwn3\\+ > *:nth-child(1) {\n      grid-area: table;\n}\n.comparisonTableSection_Nwn3\\+ > *:nth-child(2) {\n      grid-area: content;\n}\n.comparisonTableSection_Nwn3\\+.comparison-table-reverse {\n      grid-template-columns: 6fr 1fr 5fr;\n      grid-template-areas: \"content gap table\";\n}\n.comparisonTableSection_Nwn3\\+.comparison-table-reverse > *:nth-child(1) {\n        grid-area: content;\n}\n.comparisonTableSection_Nwn3\\+.comparison-table-reverse > *:nth-child(2) {\n        grid-area: table;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comparisonTableSection": "comparisonTableSection_Nwn3+"
};
module.exports = ___CSS_LOADER_EXPORT___;
