
import { Component, Prop, mixins } from "nuxt-property-decorator"
import { getMediaById } from "~/services/public/Pages"
import ImageUrlS3 from "~/mixins/imageUrlS3"

@Component
export default class OptimizedSource extends mixins(ImageUrlS3) {
  @Prop({ default: "", type: String }) media: string
  @Prop({ required: true }) imageData: Record<string, any>
  @Prop({ default: false, type: Boolean }) lazy: boolean
  @Prop({ default: "DATABASE_ID", type: String }) idType: string
  @Prop({ type: String, default: null }) size: string

  image: Record<string, any> = {
    width: "auto",
    height: "auto"
  }

  getBestImage(images) {
    let bestImage = {
      sourceUrl: "",
      width: 0,
      height: 0
    }
    const minWidth = window.innerWidth

    images.forEach(element => {
      if (
        !bestImage.width ||
        (Number(element.width) > Number(bestImage.width) && Number(bestImage.width) < minWidth) ||
        (Number(element.width) >= minWidth &&
          (Number(bestImage.width) < minWidth || Number(element.width) < Number(bestImage.width)))
      ) {
        bestImage = Object.assign(bestImage, element)
      }
    })

    return bestImage
  }

  head() {
    if (!this.lazy && this.media) {
      return {
        link: [
          {
            rel: "preload",
            as: "image",
            imagesrcset: this.image.sourceUrl
              ? this.image.sourceUrl
              : this.replaceUrlS3(this.imageData.url),
            media: this.media
          }
        ]
      }
    }
  }

  async fetch() {
    if (this.imageData.id) {
      try {
        const response = await getMediaById(this.$config, this.imageData.id, this.idType)

        if (response.data && response.data.mediaItem) {
          const media = response.data.mediaItem
          const selectedSize =
            media.mediaDetails.sizes && this.size && this.size != "full"
              ? media.mediaDetails.sizes.find(size => size.name === this.size)
              : null

          if (selectedSize) {
            this.image.width = selectedSize.width
            this.image.height = selectedSize.height
            this.image.sourceUrl = this.replaceUrlS3(selectedSize.sourceUrl)
            this.image.srcSet = null
            this.image.altText = media.altText
          } else {
            this.image = media
            this.image.srcSet = this.replaceUrlS3(this.image.srcSet)

            if (process.client && media.mediaDetails.sizes && media.mediaDetails.sizes.length > 1) {
              media.mediaDetails.sizes.push({
                width: this.image.mediaDetails.width,
                height: this.image.mediaDetails.height,
                sourceUrl: this.image.sourceUrl
              })

              const theBestImage = this.getBestImage(media.mediaDetails.sizes)

              this.image.width = theBestImage.width
              this.image.height = theBestImage.height
              this.image.sourceUrl = this.replaceUrlS3(theBestImage.sourceUrl)
            } else {
              this.image.width = this.image.mediaDetails.width
              this.image.height = this.image.mediaDetails.height
              this.image.sourceUrl = this.replaceUrlS3(media.sourceUrl)
            }
          }
        }
      } catch (err) {
        console.log("Media error", err)
      }
    }

    if (this.imageData.width || this.imageData.height) {
      this.image.width = this.imageData.width
      this.image.height = this.imageData.height
    }
  }
}
