
import { Vue } from "nuxt-property-decorator"
import { getBlockAttributes } from "~/utils/block"
import { OTHER_SERVICES_REVERSE } from "~/constants/blocks"

export default Vue.extend({
  name: "OtherServicesSection",

  props: {
    block: {
      type: Object as () => Block,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getReverseClass(block) {
      return getBlockAttributes(block).class.includes(OTHER_SERVICES_REVERSE) ? "reverse" : ""
    }
  }
})
