
import { Component, Prop, mixins } from "nuxt-property-decorator"
import Blocks from "~/mixins/blocks"
import CoreBlock from "~/components/generic/CoreBlock.vue"

@Component({
  components: { CoreBlock }
})
export default class ComparisonTable extends mixins(Blocks) {
  @Prop({ required: true, type: Object }) block: Record<string, any>
}
