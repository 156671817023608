// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.accordion_tvbms {\n  position: relative;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n}\n.accordion_tvbms:before {\n  position: absolute;\n  top: 16px;\n  left: 0;\n  --tw-bg-opacity: 1;\n  background-color: rgba(65, 165, 221, 1);\n  background-color: rgba(65, 165, 221, var(--tw-bg-opacity));\n  --tw-content: '';\n  content: '';\n  content: var(--tw-content);\n    width: 4px;\n    height: calc(100% - 32px);\n}\n.accordion_tvbms:first-child {\n    border-top: 1px solid rgba(0, 0, 0, 0.1);\n}\n.accordion_tvbms.stateActive_mGgSR .accordionTitle_RN7n0 .icon_bZi94 {\n      transform: rotate(-90deg);\n}\n.accordion_tvbms.stateActive_mGgSR .panelCollapse_YXvCM {\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n.accordion_tvbms .accordionTitle_RN7n0 {\n  display: inline-flex;\n  width: 100%;\n  cursor: pointer;\n  align-items: center;\n  justify-content: space-between;\n  gap: 30px;\n  padding: 16px;\n  padding-left: 20px;\n  font-weight: 700;\n}\n.accordion_tvbms .accordionTitle_RN7n0 .icon_bZi94 {\n  position: relative;\n  margin-top: 6px;\n  margin-bottom: 6px;\n      color: rgba(0, 0, 0, 0.5);\n      transform: rotate(90deg);\n      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.25s;\n}\n.accordion_tvbms .panelCollapse_YXvCM {\n  padding-left: 20px;\n  padding-right: 16px;\n}\nsection .accordion_tvbms .av-heading {\n  margin-bottom: 0;\n  font-size: 1rem;\n}\nsection .accordion_tvbms .av-paragraph {\n  margin-bottom: 8px;\n  font-size: 1rem;\n}\n@media (min-width: 768px) {\n.accordion_tvbms .accordionTitle_RN7n0 {\n    gap: 60px;\n}\nsection .accordion_tvbms .av-heading {\n    font-size: 1.5rem;\n}\nsection .accordion_tvbms .av-paragraph {\n    font-size: 1.25rem;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "accordion_tvbms",
	"stateActive": "stateActive_mGgSR",
	"accordionTitle": "accordionTitle_RN7n0",
	"icon": "icon_bZi94",
	"panelCollapse": "panelCollapse_YXvCM"
};
module.exports = ___CSS_LOADER_EXPORT___;
