import { BorderBoxStyle, BorderStyle, SpacingStyle, TypographyStyle } from "~/types/styled-block"
import { CORE_COLUMN, CORE_GALLERY, CORE_IMAGE } from "~/constants/blocks"

export function getBlockAttribute(attributes: BlockAttribute[], attributeName) {
  if (!attributes || !attributeName) {
    return ""
  }

  const attribute = attributes.find(attribute => attribute.name === attributeName)

  return attribute ? attribute.value : ""
}

export function getBlockAttributes(block) {
  const typeClass = block.type
    ? block.type.replace("CORE", "av").replace("_", "-").toLowerCase()
    : ""

  const attrs = {
    class: typeClass
  }

  block.attributes.forEach(attr => {
    let attrName = ""
    let attrValue: any | null = null

    if (
      [
        "align",
        "className",
        "backgroundColor",
        "textColor",
        "textAlign",
        "borderColor",
        "fontSize",
        "verticalAlignment",
        "columns"
      ].includes(attr.name)
    ) {
      attrName = "class"
      attrValue = makeBlockClass(
        attr.name,
        attr.value,
        // @ts-ignore
        attrs.class || "",
        block.type
      )
    } else if (["style", "layout"].includes(attr.name)) {
      attrName = "style"

      const rawValue = JSON.parse(attr.value)
      let formattedStyle = {}

      // @ts-ignore
      if (typeof attrs.style !== "undefined") {
        // @ts-ignore
        formattedStyle = JSON.parse(JSON.stringify(attrs.style))
      }

      if (attr.name === "style") {
        if (rawValue.border) {
          Object.assign(
            formattedStyle,
            defineBorder(rawValue.border as BorderStyle),
            defineBoxBorder(rawValue.border as BorderBoxStyle)
          )
        }

        if (rawValue.spacing) {
          Object.assign(formattedStyle, defineSpacing(rawValue.spacing as SpacingStyle))
        }

        if (rawValue.typography) {
          Object.assign(formattedStyle, defineTypography(rawValue.typography as TypographyStyle))
        }
      }

      if (attr.name === "layout") {
        Object.assign(formattedStyle, defineLayout(rawValue))
      }

      attrValue = JSON.parse(JSON.stringify(formattedStyle))
    } else if (attr.name === "width" && block.type === CORE_COLUMN) {
      // @ts-ignore
      if (typeof attrs.style === "undefined") {
        Object.assign(attrs, { style: {} })
      }

      // @ts-ignore
      Object.assign(attrs.style, { "flex-basis": attr.value })
      return
    } else {
      // console.warn('Unsupported attribute ' + attr.name + ' supplied')
      return
    }

    if (Object.entries(attrValue).length) {
      Object.assign(attrs, { [attrName]: attrValue })
    }
  })

  if (block.innerHtml.match("is-not-stacked-on-mobile")) {
    const classes = (attrs.class || "") + " is-not-stacked-on-mobile"
    Object.assign(attrs, { class: classes })
  }

  if (block.innerHtml.match("is-cropped")) {
    const classes = (attrs.class || "") + " is-cropped"
    Object.assign(attrs, { class: classes })
  }

  return attrs
}

export function makeBlockClass(
  attributeName: string,
  attributeValue: string,
  existingClasses: string,
  type: string
): string {
  let newClass = existingClasses

  switch (attributeName) {
    case "align":
      if (type === CORE_IMAGE) {
        switch (attributeValue) {
          case "left":
            newClass += " float-left mr-40 ml-0 my-20"
            break
          case "right":
            newClass += " float-right ml-40 mr-0 my-20"
            break
          case "center":
            newClass += " flex flex-col items-center"
        }
      } else if (type === CORE_GALLERY) {
        switch (attributeValue) {
          case "left":
            newClass += " flex-start"
            break
          case "right":
            newClass += " flex-end"
            break
          case "center":
            newClass += " justify-center"
        }
      } else {
        newClass += ["left", "center", "right"].includes(attributeValue)
          ? " text-" + attributeValue
          : " " + attributeValue + "-width"
      }
      break
    case "verticalAlignment":
      switch (attributeValue) {
        case "top":
          newClass += " items-start"
          break
        case "center":
          newClass += " items-center"
          break
        case "bottom":
          newClass += " items-end"
      }
      break
    case "className":
      newClass += " " + attributeValue
      break
    case "backgroundColor":
      newClass += " bg-" + attributeValue
      break
    case "textColor":
      newClass += " text-" + attributeValue
      break
    case "textAlign":
      newClass += " text-" + attributeValue
      break
    case "borderColor":
      newClass += " border-" + attributeValue
      break
    case "fontSize":
      // eslint-disable-next-line no-case-declarations
      const fontSizes = {
        "x-small": "xs",
        small: "sm",
        medium: "base",
        large: "lg",
        "x-large": "xl",
        "xx-large": "2xl",
        "xxx-large": "3xl",
        "xxxx-large": "4xl",
        "xxxxx-large": "5xl",
        "xxxxxx-large": "6xl",
        "xxxxxxx-large": "7xl",
        "xxxxxxxx-large": "8xl"
      }

      newClass += " ms-" + fontSizes[attributeValue]
      break
    case "columns":
      newClass += " " + attributeName + "-" + attributeValue
      break
  }

  return newClass
}

function presetColorVar(value: string): string {
  return value.replace(/^var:preset\|color\|([-a-z]+)$/, "var(--preset--color--$1)")
}

function defineBorder(border?: BorderStyle): object {
  if (!border) {
    return {}
  }
  return {
    "border-color": border.color,
    "border-radius": typeof border.radius === "string" ? border.radius : undefined,
    "border-width": border.width
  }
}

function defineBoxBorder(border?: BorderBoxStyle): object {
  if (!border) {
    return {}
  }
  const radius = {
    "border-radius-top-left": border.radius?.topLeft,
    "border-radius-top-right": border.radius?.topRight,
    "border-radius-bottom-right": border.radius?.bottomRight,
    "border-radius-bottom-left": border.radius?.bottomLeft
  }

  const color = {
    "border-top-color": border.top?.color ? presetColorVar(border.top?.color) : undefined,
    "border-right-color": border.right?.color ? presetColorVar(border.right?.color) : undefined,
    "border-bottom-color": border.bottom?.color ? presetColorVar(border.bottom?.color) : undefined,
    "border-left-color": border.left?.color ? presetColorVar(border.left?.color) : undefined
  }

  const width = {
    "border-top-width": border.top?.width,
    "border-right-width": border.right?.width,
    "border-bottom-width": border.bottom?.width,
    "border-left-width": border.left?.width
  }

  return {
    ...radius,
    ...color,
    ...width
  }
}

function defineSpacing(spacing?: SpacingStyle): object {
  if (!spacing) {
    return {}
  }

  return {
    "margin-top": spacing.margin?.top,
    "margin-right": spacing.margin?.right,
    "margin-bottom": spacing.margin?.bottom,
    "margin-left": spacing.margin?.left,

    "padding-top": spacing.padding?.top,
    "padding-right": spacing.padding?.right,
    "padding-bottom": spacing.padding?.bottom,
    "padding-left": spacing.padding?.left,
    gap: spacing.blockGap
  }
}

function defineTypography(typography?: TypographyStyle): object {
  if (!typography) {
    return {}
  }

  return {
    "font-size": typography.fontSize,
    "font-style": typography.fontStyle,
    "font-weight": typography.fontWeight,
    "letter-spacing": typography.letterSpacing,
    "text-transform": typography.textTransform
  }
}

function defineLayout(layout): object {
  if (!layout) {
    return {}
  }

  let flex = {}

  if (layout.type && layout.type === "flex") {
    flex = {
      display: "flex",
      "flex-wrap": layout.flexWrap,
      "justify-content": layout.justifyContent
    }
  }

  return {
    ...flex
  }
}

export function getAttributesWithoutStyles(block) {
  const attributesObject = getBlockAttributes(block)
  const objectWithoutStyles = { ...attributesObject } as { style?: any }
  delete objectWithoutStyles.style
  return objectWithoutStyles
}

export function removeParagraphClass(attrs) {
  attrs.class = attrs.class.replace("av-paragraph ", "")
  return attrs
}

export function removeDomainFromUrl(url, frontendHost) {
  const path = url.match(/^https?:\/\/[A-Za-z0-9:.-]*(.*\/?)$/)
  if (path[1] === "/") {
    return frontendHost
  }

  if (path) {
    return path[1].replace(/\/$/, "")
  }
}
