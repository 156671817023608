
import { Vue } from "nuxt-property-decorator"
import { mapGetters } from "vuex"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import { SnowplowService } from "~/services/public/Snowplow"
import { LINK_PHONE } from "~/constants/blocks"

export default Vue.extend({
  data() {
    return {
      snowplowService: new SnowplowService(),
      globalStylingClass: LINK_PHONE
    }
  },
  props: {
    block: { required: true, type: Object as () => Block }
  },
  computed: {
    ...mapGetters("phone-number", {
      phone_getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    })
  },
  methods: {
    handleClick(phoneNumber: string, extension: string) {
      // @ts-ignore
      this.snowplowService.trackCallUs({
        phoneNumber,
        label: "Offline Header",
        extension,
        action: "click"
      })
    }
  }
})
