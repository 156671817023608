
import axios from "axios"

export default {
  name: "TrustPilotWidget",
  data() {
    return {
      trustPilotReviewCount: 141321
    }
  },
  async created() {
    // @ts-ignore
    const trustPilotConfig = this.$config.trustPilot
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotConfig.id}`
    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotConfig.apiKey
        }
      })
      if (response.status === 200 && response.data) {
        // @ts-ignore
        this.trustPilotReviewCount = Number(response.data.numberOfReviews.total).toLocaleString()
      }
    } catch (err) {
      console.log("Trustpilot error", err)
    }
  },

  methods: {
    goToTP() {
      window.location.href = "https://www.trustpilot.co.uk/review/anyvan.com"
    }
  }
}
