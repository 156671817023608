
import { Component, Getter, Prop } from "nuxt-property-decorator"
import Header from "~/mixins/header"
import { SnowplowService } from "~/services/public/Snowplow"
import { GET_USER } from "~/store/auth/getters"

@Component
export default class TheHeaderComponent extends Header {
  @Prop({ default: false, type: Boolean }) isRebrand!: boolean
  @Getter(GET_USER, { namespace: "auth" }) user?: any

  isMouseInside = false
  mouseTimeoutID: any
  snowplowService: SnowplowService

  handleMouseEnterPanelMenu() {
    this.isMouseInside = true
    if (this.mouseTimeoutID) {
      clearTimeout(this.mouseTimeoutID)
    }
  }

  handleMouseLeavePanelMenu() {
    this.isMouseInside = false
    this.mouseTimeoutID = setTimeout(() => {
      if (!this.isMouseInside) {
        this.hidePanelMenu()
      }
    }, 500)
  }

  hidePanelMenu() {
    this.showPanelMenu = false
    this.showMenuSection = false
  }

  handleMenuLeave() {
    this.handleMouseLeavePanelMenu()
  }

  handleCalUsClick(phoneNumber: string, extension?: string) {
    this.snowplowService.trackCallUs({
      phoneNumber,
      label: "navigation_button",
      action: "click",
      extension
    })
  }

  get username(): string {
    if (!this.user) {
      return ""
    }
    return this.user?.username || ""
  }

  trackMenuClick(label: string, property: string) {
    this.snowplowService.trackMegaMenuClickEvent(label,property)
  }

  mounted() {
    this.snowplowService = new SnowplowService()
  }
}
